import * as React from "react";
import { version } from "../../../package.json";

// @ts-ignore
import GithubLogo from "../assets/github.svg";

import "./Footer.css";

// @ts-ignore
const githash = VERSION;

const FooterComponent: React.FC<{}> = () => {
  return (
    <footer>
      <div>
        <a href="https://twitter.com/KennyPirman" target="_blank">
          @kennethPirman
        </a>
        {" | "}
        {githash}
        {" | "}v{version}
      </div>
      <div>
        <a href="https://github.com/kenjinp/kennycraft" target="_blank">
          <img src={GithubLogo} alt="Github Logo" />
        </a>
      </div>
    </footer>
  );
};

export default FooterComponent;
