import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";

const elem = document.createElement("div");
elem.id = "app";
document.body.appendChild(elem);

const renderApp = () => {
  return ReactDOM.render(<App />, document.getElementById("app"));
};

renderApp();
