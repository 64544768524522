import * as React from "react";
import FooterComponent from "./components/Footer";

import "./App.css";
import ServerStatusContainer from "./containers/ServerStatusContainer";
import PlayersContainer from "./containers/PlayersContainer";

const App: React.FC = () => {
  return (
    <div className="App">
      <div className="center">
        <h1 className="hero">KENNYCRAFT!</h1>
        <h2>
          Easy & Automagical Minecraft <br /> deployments for your friends.
        </h2>
        <ServerStatusContainer />
        <PlayersContainer />
      </div>
      <FooterComponent />
    </div>
  );
};

export default App;
