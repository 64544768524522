import * as React from "react";
import * as skinview3d from "skinview3d";

const SkinContainer: React.FC<{ skinURL: string }> = ({ skinURL }) => {
  const ref = React.useRef<HTMLCanvasElement | null>(null);

  React.useEffect(() => {
    if (ref.current) {
      console.log({ skinURL });

      const skin = new skinview3d.SkinViewer({
        canvas: ref.current,
        width: 300,
        height: 400,
        skin: `data:image/png;charset=utf-8;base64,${skinURL}`,
      });

      let walk = skin.animations.add(skinview3d.WalkingAnimation);
      // Add another animation
      let rotate = skin.animations.add(skinview3d.RotatingAnimation);

      let control = skinview3d.createOrbitControls(skin);
      control.enableRotate = true;
      control.enableZoom = false;
      control.enablePan = false;
    }
  }, [ref]);

  return <canvas ref={ref}></canvas>;
};

export default SkinContainer;
