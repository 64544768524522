import * as React from "react";
import useAsyncCall from "../hooks/useAsyncCall";
import axios from "axios";
import SkinContainer from "../components/SkinContainer";

const requestPlayers = async () => {
  const { data } = await axios.get<string[]>(
    "https://minecraft.kenny.wtf/server/players"
  );

  return await Promise.all(
    data.map(async (playerName) => {
      const { data } = await axios.get(
        `https://api.ashcon.app/mojang/v2/user/${playerName}`
      );
      return data;
    })
  );
};

const PlayersContainer = () => {
  const players = useAsyncCall<any, Error>(requestPlayers(), []);
  return (
    <div>
      {!!players.data?.length && <h2>Recent Players</h2>}
      {players.data?.map((playerData: any) => (
        <div>
          <h3>{playerData.username}</h3>
          <SkinContainer
            skinURL={playerData.textures.skin.data}
          ></SkinContainer>
        </div>
      )) || null}
    </div>
  );
};

export default PlayersContainer;
