import * as React from "react";
import useAsyncCall from "../hooks/useAsyncCall";
import axios from "axios";
import ServerStatusComponent from "../components/ServerStatus";
import { MinecraftServerStatus } from "../../types/minecraft";

const requestServerStatus = async () => {
  const { data } = await axios.get("/status");
  return data;
};

const ServerStatusContainer = () => {
  const serverStatus = useAsyncCall<MinecraftServerStatus, Error>(
    requestServerStatus(),
    []
  );

  const onRestart = async () => {
    await axios.get("/start");
  };
  return (
    <ServerStatusComponent
      status={serverStatus}
      name="Modded to Hell"
      onRestart={onRestart}
    />
  );
};

export default ServerStatusContainer;
