import * as React from "react";
import { match, when } from "ts-pattern";
import { MinecraftServerStatus } from "../../types/minecraft";

import { AsyncCall } from "../hooks/useAsyncCall";

import "./ServerStatus.css";

const ServerStatusComponent: React.FC<{
  status: AsyncCall<MinecraftServerStatus>;
  name: string;
  onRestart: () => void;
}> = ({ status, name, onRestart }) => {
  const handleRestart = () => {
    onRestart();
  };

  const statusIndicator = match(status)
    .with(
      {
        loading: true,
      },
      () => <div className="status-indicator loading"></div>
    )
    .with(
      {
        data: when((data) => !!data?.online),
      },
      () => <div className="status-indicator online"></div>
    )
    .with(
      {
        data: when((data) => !data?.online),
      },
      () => <div className="status-indicator offline"></div>
    )
    .otherwise(() => <div className="status-indicator unknown"></div>);

  return (
    <div>
      <div className="server-status" onClick={handleRestart}>
        <div>{name}</div>

        <div>{statusIndicator}</div>
      </div>
      <div>
        <p>
          <em>{status.data?.online ? "online" : "offline"}</em>
          {" | "}
          <em>
            {status.data?.serverInfo?.currentPlayers
              ? `${status.data?.serverInfo?.currentPlayers} player(s)`
              : "nobody logged in"}
          </em>
        </p>
      </div>
    </div>
  );
};

export default ServerStatusComponent;
